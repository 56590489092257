import { Disclosure, Transition } from "@headlessui/react";
import { Icon } from "@uxf/ui/icon";
import { ReactNode } from "react";

interface Props {
    answer: ReactNode;
    question: string;
}

export function FaqItem(props: Props) {
    return (
        <Disclosure as="div" className="w-full border-b p-4">
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex w-full items-center justify-between text-left ">
                        <div className="text-large font-medium">{props.question}</div>
                        <Icon className="text-textHigh" size={20} name={open ? "chevronDown" : "chevronRight"} />
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-90 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-100 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-90 opacity-0"
                    >
                        <Disclosure.Panel className="max-w-[1200px] pb-6 pt-4 text-label text-textLow transition">
                            {props.answer}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
}
