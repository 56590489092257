import { routeToUrl } from "@app-routes";
import { config } from "@config/config";
import { FaqItem } from "@ui/components/homepage/faq/faq-item";
import { TextLink } from "@uxf/ui/text-link";
import Link from "next/link";
import { ReactNode } from "react";

type FaqItem = { question: string; answer: ReactNode };

const FAQ_ITEMS: FaqItem[] = [
    {
        question: "Proč vznikl projekt Energetická Expedice?",
        answer: (
            <p>
                Nadační fond Energie pomáhá &nbsp;dodavatel energií Centropol vnímají, že je energetická gramotnost
                důležitou dovedností každého z&nbsp;nás, &nbsp;i v&nbsp;následujících letech bude velkým tématem
                rezonujícím společností. A&nbsp;právě děti se&nbsp;o&nbsp;takových věcech mají dovídat co nejdříve. Díky
                nově nabytým znalostem mohou navíc vést své rodiče k&nbsp;efektivním způsobům úspory energií
                v&nbsp;domácnosti, tím přispět k&nbsp;udržitelnějšímu nakládání s&nbsp;energiemi.
            </p>
        ),
    },
    {
        question: "Jak se\xa0do projektu přihlásit?",
        answer: (
            <p>
                Stačí kliknout n&nbsp;tlačítko{" "}
                <Link href={routeToUrl("auth/register")} className="font-medium underline underline-offset-2">
                    „Registrovat školu“
                </Link>
                , vyplnit pár základních informací o&nbsp;vaší škole a&nbsp;žádost odeslat. My se&nbsp;vám následně do
                48&nbsp;hodin ozveme a&nbsp;probereme s&nbsp;vámi vše potřebné.
            </p>
        ),
    },
    {
        question: "Pro které ročníky je Energetická expedice vhodná?",
        answer: (
            <p>
                Projekt je ideální pro žáky 8.&nbsp;tříd. Pro sedmáky může být složitější, pokud se&nbsp;ještě
                v&nbsp;hodinách fyziky neučili o&nbsp;elektřině a&nbsp;v matematice neprobírali procenta. Vše jsme ale
                připravili tak, aby to zvládli i&nbsp;studenti bez hlubších znalostí z&nbsp;oblasti energetiky. Svým
                obsahem je projekt určitě vhodný i&nbsp;pro deváťáky, doporučujeme ho ale zařadit do&nbsp;období, kdy
                se&nbsp;ještě nevěnují přijímačkám na&nbsp;střední školy.
            </p>
        ),
    },
    {
        question: "Na koho se\xa0mohu obrátit s\xa0dotazem?",
        answer: (
            <p>
                Pokud máte nějaké další otázky, napište nám na&nbsp;{" "}
                <TextLink href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</TextLink>.
            </p>
        ),
    },
];

export function FaqSection() {
    return (
        <div className="container mt-6 max-w-[1088px] px-6 pb-4 pt-16 lg:px-0">
            <div>
                <h2 className="uxf-typo-h4 px-4 py-6 text-textHigh">Mohlo by vás zajímat</h2>
                <div>
                    {FAQ_ITEMS.map((item, index) => (
                        <FaqItem key={index} answer={item.answer} question={item.question} />
                    ))}
                </div>
            </div>
        </div>
    );
}
