interface Props {
    src: string;
    title: string;
}

export function VideoIframe(props: Props) {
    return (
        <div className="aspect-video">
            <iframe
                allowFullScreen
                className="size-full rounded-2xl"
                loading="lazy"
                src={props.src}
                title={props.title}
            />
        </div>
    );
}
