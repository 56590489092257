import StickerHomepage from "@public/images/illustrations/stickers/sticker-corner-gray.svg";

type ReferenceItem = { school: string; address: string; description: string; person: string };

const REFERENCES_ITEMS: ReferenceItem[] = [
    {
        school: "ZŠ Járy Cimrmana",
        address: "Praha 6 – Lysolaje",
        description:
            "Projekt jsem si užil, ale také jsem se\xa0toho hodně dozvěděl. Pomocí měřící zásuvky, jsem se\xa0dozvěděl, jak moc elektřiny spotřebuje lednice, počítač, pračka a\xa0plno dalších domácích spotřebičů. Naučil jsem se, jak šetřit elektřinu jednoduchou a\xa0zábavnou formou. Proto tento program hodnotím pozitivně a\xa0všem bych ho doporučil.",
        person: "Matěj\xa0P. 8.B",
    },
    {
        school: "ZŠ Elišky Krásnohorské ",
        address: "Ústí nad Labem",
        description:
            "Projekt byl pro děti velmi zajímavý, přínosný. Vše bylo podáváno zábavnou a\xa0poutavou formou. Pozitivně hodnotím velmi hezký a\xa0milý přístup lektorek.",
        person: "Třídní učitelka 8.A",
    },
    {
        school: "ZŠ Tyršova",
        address: "Praha 5 – Jinonice",
        description:
            "V\xa0červnu a\xa0v září 2023 u\xa0nás ve\xa0škole proběhl projekt výuky energetické gramotnosti pro žáky 8.\xa0a 9.\xa0tříd. Žáci se\xa0učili zábavnou formou, jak u\xa0sebe doma šetřit elektřinu, což si i\xa0o prázdninách mohli vyzkoušet a\xa0svá zjištění v\xa0rámci druhého projektového dne prezentovat ostatním. Téma se\xa0výborně hodilo do\xa0výuky fyziky v\xa0těchto ročnících. Největší přínos projektu vidím to, že si žáci ověřili potřebnost teoretických vědomostí v\xa0praxi a\xa0často zapojili i\xa0celou rodinu.",
        person: "Třídní učitelka 8.A",
    },
];

export function References() {
    return (
        <div className="mx-6 lg:mx-0">
            <div
                className="container relative mb-10 flex max-w-[1088px] justify-center border-8 p-12 text-textHigh sm:mb-20"
                style={{
                    borderImage: "url(/images/illustrations/paper/paper-full.svg) 8",
                }}
            >
                <div>
                    <h2 className="uxf-typo-h4 pb-10">Reference</h2>
                    <div className="grid gap-8 md:grid-cols-3">
                        {REFERENCES_ITEMS.map((reference, index) => (
                            <div key={index} className="uxf-typo-small flex flex-col md:max-w-[280px]">
                                <p className="font-bold">{reference.school}</p>
                                <p className="pb-4">{reference.address}</p>
                                <p>{reference.description}</p>
                                <p className="pt-4 font-medium">{reference.person}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <StickerHomepage className="absolute -top-16 right-[25%] max-sm:rotate-[-16deg] sm:-right-12 sm:-top-12" />
                <StickerHomepage className="absolute -bottom-10 -left-16 max-sm:hidden" />
            </div>
        </div>
    );
}
