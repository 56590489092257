export interface HomepageInfoItem {
    title: string;
    listItems: string[];
}

interface Props {
    item: HomepageInfoItem;
}

export function HomepageInfo(props: Props) {
    return (
        <div className="pt-6 text-textHigh max-md:max-w-[480px] max-md:justify-self-center md:pt-0">
            <h2 className="uxf-typo-h4 pb-6">{props.item.title}</h2>
            <ul className="uxf-typo-large ml-4 list-outside list-disc space-y-4 pl-2">
                {props.item.listItems.map((listItem) => (
                    <li key={listItem}>{listItem}</li>
                ))}
            </ul>
        </div>
    );
}
