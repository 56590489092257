import ArrowDownLeftShort from "@public/images/illustrations/arrows/arrow-down-left-short.svg";
import ArrowDownRightShort from "@public/images/illustrations/arrows/arrow-down-right-short.svg";
import { HomepageInfo, HomepageInfoItem } from "@shared/components/homepage-info/homepage-info";
import { VideoIframe } from "@ui/components/iframe/video-iframe";
import { ImageGallery } from "@uxf/ui/image-gallery";

export const ABOUT_ITEMS: HomepageInfoItem[] = [
    {
        title: "Co vás čeká a\xa0nemine:",
        listItems: [
            "Dva projektové dny ve\xa0škole",
            "Dvouměsíční domácí bádání",
            "Praktické úkoly s\xa0automatickým vyhodnocením",
            "Interaktivní online mapa",
        ],
    },
    {
        title: "Proč zapojit školu do\xa0projektu?",
        listItems: [
            "Závažná témata podáváme poutavou formou",
            "Dbáme na\xa0kvalitní obsah pod supervizí dodavatele energií Centropol Energy a.s.",
            "Poskytujeme výukové materiály",
            "Nabyté znalosti jsou využitelné v\xa0reálném životě",
            "Učíme pracovat v\xa0týmu i\xa0samostatně",
        ],
    },
    {
        title: "Přínos expedice:",
        listItems: [
            "Praktické využití znalostí z\xa0fyziky",
            "Vliv spotřeby elektřiny a\xa0plynu na\xa0rozpočet domácnosti včetně výpočtů",
            "Inspirace na\xa0úsporná opatření",
            "Příprava k\xa0příjimačkám na\xa0gymnázia",
        ],
    },
];

export function AboutSection() {
    return (
        <div className="container relative max-w-[1088px] px-8 pb-20 pt-10 md:my-16 md:py-16">
            <div className="grid items-center gap-6 md:grid-cols-2 md:gap-x-8 md:gap-y-20">
                <div className="text-center md:absolute md:-top-12 md:left-4 md:rotate-[-8deg] xl:-left-20 xl:-top-4">
                    <p className="uxf-typo-note text-secondary md:max-w-[150px]">Podívejte se, jak projekt vypadá!</p>
                    <ArrowDownRightShort className="absolute -left-4 top-16 rotate-[9deg] max-md:hidden" />
                </div>
                <VideoIframe
                    src="https://www.youtube.com/embed/g16fg_lzNio?rel=0&modestbranding=1"
                    title="Video představení projektu"
                />
                <HomepageInfo item={ABOUT_ITEMS[0]} />
                <div className="pt-2 text-center max-md:justify-self-center md:absolute md:right-6 md:top-4 md:rotate-[7deg] md:pt-0 xl:-right-10 xl:top-24">
                    <p className="uxf-typo-note max-w-[220px] text-secondary md:max-w-[180px]">
                        Žádná zbytečná práce pro vyučujícího navíc
                    </p>
                    <ArrowDownLeftShort className="absolute max-md:hidden xl:-left-24 xl:top-0 xl:hidden xl:rotate-[180deg]" />
                    <ArrowDownRightShort className="absolute -left-20 -top-4 rotate-[120deg] max-xl:hidden" />
                </div>
                <HomepageInfo item={ABOUT_ITEMS[1]} />
                <ImageGallery>
                    <ImageGallery.Image
                        alt="Ukázka mapy"
                        className="w-[340px] max-w-full pt-4 shadow-md max-md:justify-self-center md:justify-self-end md:pt-0"
                        src="/images/illustrations/screens/hp-screenshot-map.svg"
                    />
                </ImageGallery>
                <ImageGallery>
                    <ImageGallery.Image
                        alt="Ukázka úkolu"
                        className="w-[410px] max-w-full pt-2 drop-shadow-md max-md:order-last max-md:justify-self-center md:justify-self-start md:pt-0"
                        src="/images/illustrations/screens/hp-screenshot-task.svg"
                    />
                </ImageGallery>
                <HomepageInfo item={ABOUT_ITEMS[2]} />
            </div>
        </div>
    );
}
